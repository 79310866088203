import React, { useEffect, useState, useContext } from "react";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";
import sanitizeHtml from "sanitize-html";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PageWrapper from "../../components/PageWrapper";
import LastSeen from "../../components/LastSeen";
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import { fetchJobDetails, updateJob } from "../../api";
import notify from "../../utils/notify";
import { login } from "../../utils/utilities";
import iconD from "../../assets/image/svg/icon-dolor.svg";
import iconB from "../../assets/image/svg/icon-briefcase.svg";
import iconL from "../../assets/image/svg/icon-location.svg";
import iconBid from "../../assets/image/svg/icon-bids.svg";

const JobDetails = ({ location, params }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [job, setJob] = useState(null);
  const { auth } = useContext(AuthContext);
  const { placeBid } = useContext(DataContext);
  const param = params[`*`];

  const getJobDetails = async (jobId) => {
    const details = await fetchJobDetails(jobId);
    setJob(details);
    setLoading(false);
  };

  useEffect(() => {
    const jobId = param;
    getJobDetails(jobId);
  }, []);

  const createMarkup = (html) => {
    return {
      __html: sanitizeHtml(html, {
        allowedAttributes: false,
        allowedTags: [
          "address",
          "article",
          "aside",
          "footer",
          "header",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "hgroup",
          "main",
          "nav",
          "section",
          "blockquote",
          "dd",
          "div",
          "dl",
          "dt",
          "figcaption",
          "figure",
          "hr",
          "li",
          "main",
          "ol",
          "p",
          "pre",
          "ul",
          "a",
          "abbr",
          "b",
          "bdi",
          "bdo",
          "br",
          "cite",
          "code",
          "data",
          "dfn",
          "em",
          "i",
          "kbd",
          "mark",
          "q",
          "rb",
          "rp",
          "rt",
          "rtc",
          "ruby",
          "s",
          "samp",
          "small",
          "span",
          "strong",
          "sub",
          "sup",
          "time",
          "u",
          "var",
          "wbr",
          "caption",
          "col",
          "colgroup",
          "table",
          "tbody",
          "td",
          "tfoot",
          "th",
          "thead",
          "tr",
        ],
      }),
    };
  };

  const handlePlaceBid = async (jobId) => {
    if (auth && auth.talent) {
      setSubmitting(true);
      try {
        const data = {
          account_id: auth.talent.account_id,
          firstname: auth.talent.firstname,
          created: new Date(),
        };
        const response = await placeBid(jobId, "application", data);
        if (response === true) {
          notify("Bid sent successfuly", "success");
          setSubmitting(false);
          setLoading(true);
          // Refetch job details
          await getJobDetails(jobId);
        } else {
          notify(response.message);
          setSubmitting(false);
        }
      } catch (error) {
        notify(error.message);
        setSubmitting(false);
      }
    } else if (auth && !auth.talent) {
      notify("Only talents can apply to jobs");
    } else if (!auth) {
      await login(location.pathname, "/search-list");
    } else {
      notify("Login as a Talent to apply to this job");
    }
  };

  const handleDeleteJob = async (job) => {
    try {
      setSubmitting(true);
      const { id, status } = job;
      const data = {
        status: status === "archived" ? "pending" : "archived",
      };
      await updateJob(id, "delete", data);
      notify("Job status updated successfully", "success");
      setSubmitting(false);
      setLoading(true);
      await getJobDetails(id);
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setSubmitting(false);
    }
  };

  const handleJobExpired = async (job) => {
    try {
      setSubmitting(true);
      const { id, status } = job;
      let data = {
        status: "pending",
      };
      if (status != "expired") {
        data = {
          status: "expired",
        };
      }
      await updateJob(id, "admin", data);
      notify("Job status updated successfully", "success");
      setSubmitting(false);
      setLoading(true);
      await getJobDetails(id);
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setSubmitting(false);
    }
  };

  const handleShare = async (url) => {
    notify("Link copied to the clipboard", "success");
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
          <div className="container">
            <div className="row justify-content-center">
              {/* <div className="col-xl-10 col-lg-11 mt-4 ml-xxl-32 ml-xl-15 dark-mode-texts">
                <div className="mb-9">
                  <Link
                    to="#"
                    className="d-flex align-items-center ml-4"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </Link>
                </div>
              </div> */}

              <div className="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
                <div className="bg-white rounded-4 border border-mercury shadow-9">
                  {/* <!-- Single Featured Job --> */}
                  <div className="mt-4 pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row">
                      <div className="col-md-6">
                        {/* <!-- media start --> */}
                        <div className="media align-items-center">
                          {/* <!-- media logo start --> */}
                          <div className="square-72 d-block mr-8">
                            {loading ? (
                              <Skeleton width={72} height={72} />
                            ) : job?.logo ? (
                              <img
                                src={job.logo}
                                alt={`${job?.company} logo`}
                                height={72}
                                width={72}
                              />
                            ) : (
                              <img
                                src={iconB}
                                alt="Company logo placeholder"
                                height={72}
                                width={72}
                              />
                            )}
                          </div>
                          <div className="text-left">
                            <h3 className="font-size-6 mb-0">
                              {loading ? (
                                <Skeleton width={200} height={34} />
                              ) : (
                                job?.position
                              )}
                            </h3>
                            {loading ? (
                              <span className="font-size-3 text-gray line-height-2">
                                <Skeleton width={200} height={24} />
                              </span>
                            ) : job?.client ? (
                              <Link
                                to={`/client-profile/${job.client}`}
                                state={{ id: job.client }}
                                className="font-size-3 text-gray line-height-2"
                              >
                                {job?.company}
                              </Link>
                            ) : null}
                          </div>
                          {/* <!-- media texts end --> */}
                        </div>
                        {/* <!-- media end --> */}
                      </div>
                      <div className="col-md-6 text-right pt-7 pt-md-0 mt-md-n1">
                        <div className="media justify-content-md-end">
                          <p className="font-size-4 text-gray mb-0">
                            {loading ? (
                              <Skeleton width={300} height={24} />
                            ) : job?.created ? (
                              <LastSeen
                                date={new Date(job?.created.toDate())}
                              />
                            ) : null}
                          </p>
                        </div>
                        <div className="media justify-content-md-end">
                          {loading ? (
                            <Skeleton width={300} height={24} />
                          ) : (
                            <p className="font-size-3 text-gray mb-0">
                              <i
                                className={`fa fa-circle mr-2 ${job?.status === "archived"
                                  ? "text-gray"
                                  : job?.status === "active"
                                    ? "text-shamrock"
                                    : job?.status === "expired"
                                      ? "text-red"
                                      : "text-green"
                                  }`}
                              ></i>
                              <span>{job?.status}</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row pt-9">
                      <div className="col-12">
                        {/* <!-- card-btn-group start --> */}
                        <div className="card-btn-group">
                          {loading ? (
                            <Skeleton width={300} height={34} />
                          ) : (
                            <div className="d-flex align-items-center flex-wrap">
                              {auth?.admin ? (
                                <Link
                                  to={`/dashboard-admin-job-details/${job?.id}`}
                                  state={{ id: job?.id }}
                                  className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                >
                                  <span className="mr-2">Update</span>
                                  <i className="fa fa-edit"></i>
                                </Link>
                              ) : !auth?.admin && job?.applicationurl ? (
                                <a
                                  href={job?.applicationurl}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-link font-size-3 text-green text-uppercase btn-medium rounded-3 mr-6 mb-4"
                                >
                                  <span className="mr-2">
                                    Apply to this job
                                  </span>
                                  <i className="fa fa-external-link-alt"></i>
                                </a>
                              ) : auth &&
                                auth?.client?.account_id === job?.client ? (
                                <Link
                                  to={`/job-details-edit/${job?.id}`}
                                  state={{ id: job?.id }}
                                  className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                >
                                  <span className="mr-2">Update</span>
                                  <i className="fa fa-edit"></i>
                                </Link>
                              ) : (
                                <button
                                  type="link"
                                  onClick={() => handlePlaceBid(job?.id)}
                                  className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                  disabled={
                                    submitting ||
                                      (job?.bids &&
                                        job?.bids.find(
                                          ({ account_id }) =>
                                            account_id ===
                                            auth?.talent?.account_id
                                        ))
                                      ? true
                                      : false
                                  }
                                >
                                  {submitting
                                    ? "Applying..."
                                    : job?.bids &&
                                      job?.bids.find(
                                        ({ account_id }) =>
                                          account_id ===
                                          auth?.talent?.account_id
                                      )
                                      ? "Applied"
                                      : "Apply to this job"}
                                </button>
                              )}
                              {(auth?.client?.account_id === job?.client ||
                                auth?.admin) &&
                                submitting ? (
                                <Skeleton
                                  width={180}
                                  height={48}
                                  className="mr-4 mb-5"
                                />
                              ) : auth?.client?.account_id === job?.client ||
                                auth?.admin ? (
                                <button
                                  type="button"
                                  onClick={() => handleDeleteJob(job)}
                                  className={`btn ${job?.status === "archived"
                                    ? "btn-gray"
                                    : "btn-red-2"
                                    } text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset`}
                                >
                                  <span className="mr-2">
                                    {job?.status === "archived"
                                      ? "Undo Delete"
                                      : "Delete"}
                                  </span>
                                  {job?.status === "archived" ? (
                                    <i className="fa fa-history"></i>
                                  ) : (
                                    <i className="fa fa-trash-alt"></i>
                                  )}
                                </button>
                              ) : null}
                              <CopyToClipboard
                                text={location.href}
                                onCopy={() => handleShare(location.href)}
                              >
                                <button
                                  type="link"
                                  onClick={(e) => e.preventDefault()}
                                  className="btn btn-outline-dark text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                >
                                  <span className="mr-2">Share</span>
                                  <i className="fa fa-share-alt"></i>
                                </button>
                              </CopyToClipboard>
                              {auth?.admin &&
                                submitting ? (
                                <Skeleton
                                  width={180}
                                  height={48}
                                  className="mr-4 mb-5"
                                />
                              ) :
                                auth?.admin ? (
                                  <button
                                    type="button"
                                    onClick={() => handleJobExpired(job)}
                                    className={`btn ${job?.status === "expired"
                                      ? "btn-red-2"
                                      : "btn-gray"
                                      } text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset`}
                                  >
                                    <span className="mr-2">
                                      {job?.status === "expired"
                                        ? "Mark as Pending"
                                        : "Mark as Expired"}
                                    </span>
                                  </button>
                                ) : null}
                            </div>
                          )}
                        </div>
                        {/* <!-- card-btn-group end --> */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Single Featured Job --> */}
                  <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row mb-7">
                      {job?.salary === 0 || job?.rate === 0 ? null : (
                        <div className="col-md-4 mb-md-0 mb-6">
                          <div className="media justify-content-md-start">
                            <div className="image mr-5">
                              <img src={iconD} alt="currency icon" />
                            </div>
                            <p className="font-weight-semibold font-size-5 text-gray mb-0">
                              {loading ? (
                                <Skeleton width={50} height={24} />
                              ) : job?.rate ? (
                                `${job?.rate
                                  .toString()
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${job?.currency
                                } /hour`
                              ) : job?.salary === 0 ? (
                                "N/A"
                              ) : job?.rate === 0 ? (
                                "N/A"
                              ) : (
                                `${job?.salary
                                  .toString()
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${job?.currency
                                } /month`
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                      {job?.bids.length === 0 ? null : (
                        <div className="col-md-4 pr-lg-0 pl-lg-10 mb-md-0 mb-6">
                          <div className="media justify-content-md-start">
                            <div className="image mr-5">
                              <img src={iconBid} alt="" />
                            </div>
                            <Link
                              to="#list-of-bids"
                              className="font-weight-semibold font-size-5 text-gray mb-0"
                            >
                              {loading ? (
                                <Skeleton width={50} height={24} />
                              ) : (
                                `${job?.bids.length} Bid(s) Received`
                              )}
                            </Link>
                          </div>
                        </div>
                      )}
                      <div className="col-md-4 pl-lg-0">
                        <div className="media justify-content-md-start">
                          <div className="image mr-5">
                            <img src={iconL} alt="" />
                          </div>
                          <p className="font-weight-semibold font-size-5 text-gray mb-0">
                            {loading ? (
                              <Skeleton width={100} height={24} />
                            ) : (
                              job?.location
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mb-lg-0 mb-sm-10">
                        <div className="text-left">
                          <span className="font-weight-semibold font-size-4 d-block mb-4 text-gray">
                            Experience Level
                          </span>
                          <h6 className="font-weight-normal font-size-4 text-black-2 mb-9">
                            {loading ? (
                              <Skeleton width={100} height={24} />
                            ) : (
                              job?.experience
                            )}
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-4 pr-lg-0 pl-lg-10 mb-lg-0 mb-sm-10">
                        <div className="text-left">
                          <span className="font-weight-semibold font-size-4 d-block mb-4 text-gray">
                            Category
                          </span>
                          <h6 className="font-size-4 text-black-2 font-weight-normal mb-9">
                            {loading ? (
                              <Skeleton width={100} height={24} />
                            ) : (
                              job?.category
                            )}
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-4 pl-lg-0 mb-8 mb-sm-10">
                        <div className="text-left">
                          <span className="font-weight-semibold font-size-4 d-block mb-4 text-gray">
                            Subcategory
                          </span>
                          <h6 className="font-size-4 text-black-2 font-weight-normal mb-0">
                            {loading ? (
                              <Skeleton width={100} height={24} />
                            ) : (
                              job?.subcategory
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {job?.otherCategory ? (
                        <div className="col-md-4 mb-lg-0 mb-sm-10">
                          <div className="text-left">
                            <span className="font-weight-semibold font-size-4 d-block mb-4 text-gray">
                              Other Category
                            </span>
                            <h6 className="font-size-4 text-black-2 font-weight-normal mb-0">
                              {loading ? (
                                <Skeleton width={100} height={24} />
                              ) : (
                                job?.otherCategory
                              )}
                            </h6>
                          </div>
                        </div>
                      ) : null}

                      <div className="col-md-4 mb-lg-0 mb-sm-10">
                        <div className="text-left">
                          <p className="font-weight-semibold font-size-4 text-gray mb-3">
                            Technical Skill
                          </p>
                          {loading ? (
                            <Skeleton width={100} height={24} />
                          ) : (
                            <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">
                              {job?.skills &&
                                job?.skills.map((skill, index) => (
                                  <li
                                    key={index}
                                    className="bg-regent-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2"
                                  >
                                    {skill}
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts">
                    <div className="row">
                      <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
                        <div className="text-left">
                          <h6 className="font-size-5 text-black-2 font-weight-semibold">
                            Job Description
                          </h6>
                          {loading ? (
                            <Skeleton width={600} height={600} />
                          ) : (
                            <div
                              className="font-size-3 mb-7 job-description"
                              dangerouslySetInnerHTML={createMarkup(
                                job?.description
                              )}
                            />
                          )}
                        </div>
                        {auth && auth?.admin ? null : (
                          <div className="text-left">
                            {auth &&
                              auth?.client ? null : job?.applicationurl ? (
                                <a
                                  href={job.applicationurl}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="font-size-3 font-weight-bold text-green text-uppercase mr-6"
                                >
                                  <span className="mr-2">Apply to this job</span>
                                  <i className="fa fa-external-link-alt"></i>
                                </a>
                              ) : (
                              <button
                                type="link"
                                onClick={() => handlePlaceBid(job?.id)}
                                className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                disabled={
                                  submitting ||
                                    (job?.bids &&
                                      job?.bids.find(
                                        ({ account_id }) =>
                                          account_id === auth?.talent?.account_id
                                      ))
                                    ? true
                                    : false
                                }
                              >
                                {submitting
                                  ? "Applying..."
                                  : job?.bids &&
                                    job?.bids.find(
                                      ({ account_id }) =>
                                        account_id === auth?.talent?.account_id
                                    )
                                    ? "Applied"
                                    : "Apply to this job"}
                              </button>
                            )}
                            <CopyToClipboard
                              text={location.href}
                              onCopy={() => handleShare(location.href)}
                            >
                              <button
                                type="link"
                                onClick={(e) => e.preventDefault()}
                                className="btn btn-outline-dark text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                              >
                                <span className="mr-2">Share</span>
                                <i className="fa fa-share-alt"></i>
                              </button>
                            </CopyToClipboard>
                          </div>
                        )}

                        {auth?.client?.account_id === job?.client ||
                          auth?.admin ? (
                          <div className="mt-11">
                            <hr />
                            <h6
                              className="font-size-5 text-black-2 font-weight-semibold my-9 text-left"
                              id="list-of-bids"
                            >
                              List of Bids
                            </h6>
                            <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="pl-0  border-0 font-size-4 font-weight-normal text-left"
                                    >
                                      Talent Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="border-0 font-size-4 font-weight-normal text-left"
                                    >
                                      Date
                                    </th>
                                    <th
                                      scope="col"
                                      className="border-0 font-size-4 font-weight-normal"
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!job?.bids || job?.bids.length === 0 ? (
                                    <tr className="border border-color-2">
                                      <td
                                        colSpan={5}
                                        className="pl-6 border-0 py-7 pr-0"
                                      >
                                        <h4 className="font-size-4 mb-0 font-weight-normal text-black-2 text-left">
                                          No bids found for this job at the
                                          moment
                                        </h4>
                                      </td>
                                    </tr>
                                  ) : (
                                    job.bids.map((bid, index) => (
                                      <tr
                                        key={index}
                                        className="border border-color-2"
                                      >
                                        <th
                                          scope="row"
                                          className="pl-6 border-0 py-7"
                                        >
                                          <Link
                                            to={`/candidate-profile/${bid.account_id}`}
                                            state={{ id: bid.account_id }}
                                            className="min-width-px-110"
                                          >
                                            <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                              {loading ? (
                                                <Skeleton
                                                  width={140}
                                                  height={24}
                                                />
                                              ) : (
                                                `${bid?.firstname}`
                                              )}
                                            </h4>
                                          </Link>
                                        </th>
                                        <td className="py-7 min-width-px-110">
                                          <h3 className="font-size-4 font-weight-normal text-black-2 mt-2 mb-0">
                                            {loading ? (
                                              <Skeleton
                                                width={200}
                                                height={24}
                                              />
                                            ) : bid?.created ? (
                                              <LastSeen
                                                date={
                                                  new Date(
                                                    bid?.created.toDate()
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </h3>
                                        </td>
                                        <td className="py-7 text-right">
                                          {loading ? (
                                            <Skeleton width={100} height={24} />
                                          ) : (
                                            <Link
                                              to={`/candidate-profile/${bid.account_id}`}
                                              state={{ id: bid.account_id }}
                                              className="btn btn-outline text-uppercase font-size-3 text-green focus-reset"
                                            >
                                              View Talent Details
                                            </Link>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : null}

                        <div className="mt-11">
                          <hr />
                          {loading ? (
                            <Skeleton
                              height={24}
                              containerClassName="w-100"
                              className="w-100"
                            />
                          ) : job?.txhash ? (
                            <div className="d-flex flex-row flex-wrap text-truncate">
                              <span className="font-size-4 text-gray font-weight-semibold line-height-2 line-height-2 mr-2">
                                Transaction of NEAR:
                              </span>
                              <a
                                href={`https://explorer.${process.env.GATSBY_NETWORK_ID}.near.org/transactions/${job?.txhash}`}
                                target="_blank"
                                rel="noreferrer"
                                className="font-size-4 text-gray line-height-2 line-height-2"
                              >
                                <span className="mr-2">{job?.txhash}</span>
                                <i className="fa fa-external-link-alt"></i>
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default JobDetails;
